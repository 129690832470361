import React, { useState, useContext } from "react";
import { DropdownButton } from "react-bootstrap";
import { getListOfTask } from "./view_list_logic";
import DomainContext from "../domains/domainPathContext";

export const ViewDetailSelect = props => {
  const subdomain = useContext(DomainContext);
  const [taskDetails, setTaskDetails] = useState([]);

  const getTasks = async () => {
    await getListOfTask(props, setTaskDetails, subdomain);
  };

  return (
    <DropdownButton
      id="dropdown-basic-button"
      title="Ver Detalle"
      variant={"secondary"}
      onClick={() => getTasks()}
    >
      {taskDetails}
    </DropdownButton>
  );
};

export default ViewDetailSelect;
