import { useState } from 'react'

const useSuccessfulPreregistrationModal = () => {

  const [isShowing, setIsShowing] = useState(false);

  return {
    isShowing,
    setIsShowing,
  }
}

export default useSuccessfulPreregistrationModal