import React, { createContext, useContext } from 'react'
import useGlobalState from './hooks/useHandlerGlobalState';

const GlobalStateContext = createContext();
GlobalStateContext.displayName = 'GlobalStateContext';

const GlobalStateProvider = (props) => {

  const globalState = useGlobalState();

  return (
    <GlobalStateContext.Provider {...props} value={globalState} />
  )
}

export const useGlobalStateContext = () => {
  const context = useContext(GlobalStateContext);

  if (context === undefined) {
    throw new Error('useGlobalStateContext must be wrapped in a GlobalStateProvider');
  }

  return context;
}

export default GlobalStateProvider