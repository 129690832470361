import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Grid } from "react-flexbox-grid";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DomainContext from "../../domains/domainPathContext";

const TopMenu = props => {
  const subdomain = useContext(DomainContext);

  return (
    <Grid fluid={true} className="principal-nav">
      <Grid>
        <ul className="nav nav-tabs">
          <li className="nav-item mr-1">
            <Link
              to={`/${subdomain}/home`}
              className={
                "nav-link " +
                (props.pageConfig.active === "home" ? "active" : "")
              }
            >
              <i className="ti-home mr-1" />
              Home
            </Link>
          </li>
          <li className="nav-item mr-1">
            <OverlayTrigger
              placement={'bottom'}
              overlay={
                <Tooltip id={`tooltip-bottom`}>
                  Aquí encontrarás los trámites que tienes <strong> finalizados, 
                  pendientes, en revisión, cancelados y pausados</strong>.
                </Tooltip>
              }
            >
              <Link
                to={`/${subdomain}/formalities`}
                className={
                  "nav-link " +
                  (props.pageConfig.active === "my_formalities" ? "active" : "")
                }
              >
                <i className="ti-id-badge mr-1" />
                Mis trámites
              </Link>          
            </OverlayTrigger>
          </li>
          <li className="nav-item mr-1">
            <OverlayTrigger
              placement={'bottom'}
              overlay={
                <Tooltip id={`tooltip-bottom`}>
                  Aquí te podrás encontrar <strong>los trámites disponibles para realizar en línea</strong>.
                </Tooltip>
              }
            >
              <Link
                to={`/${subdomain}/procedure-list`}
                className={
                  "nav-link " +
                  (props.pageConfig.active === "all_formalities" ? "active" : "")
                }
              >
                <i className="ti-agenda mr-1" />
                Trámites disponibles
              </Link>         
            </OverlayTrigger>
          </li>
        </ul>
      </Grid>
    </Grid>
  );
};

export default TopMenu;
