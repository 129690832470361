import Cookies from "js-cookie";
import { destroyCookies } from "../login/login_logic";
import { NewClassRequest } from "../classes/NewClassRequest";
import { NotificationManager } from "react-notifications";

export const initForm = {
  oldPassword: "",
  newPassword: "",
  passwordConfirm: ""
};

/**********************************************************FORGOT PASSWORD************************************************************************/
export const changePassword = async form => {
  const { userId_02, domain_name_02 } = Cookies.get();
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/users/change/password/${userId_02}`,
    "patch",
    null,
    {
      new_password: form.newPassword,
      password_confirmation: form.passwordConfirm,
      current_password: form.oldPassword,
    }
  );
  const validateChangePassword = await request.executeRequest();

  if (
    validateChangePassword.code === 200 && 
    !validateChangePassword.response.error && 
    typeof validateChangePassword.response.message === "string" && 
    validateChangePassword.response.message !== "" ) {

      NotificationManager.success(validateChangePassword.response.message);

      setTimeout(() => {
        destroyCookies();
        window.location.href = `/${domain_name_02}`;
      }, 700);
  }
  else if (
    validateChangePassword.code === 200 && 
    (validateChangePassword.response.code === 401 || validateChangePassword.response.code === 403) && 
    validateChangePassword.response.error && 
    typeof validateChangePassword.response.message === "string" && 
    validateChangePassword.response.message !== ""
  ) {

    NotificationManager.error(validateChangePassword.response.message);
  }
  else {

    NotificationManager.error("Algo sucedió y no fue posible efectuar esta acción, inténtalo nuevamente...");
  }
};
/***********************************************************************************************************************************************/
