import React, {useContext, useEffect, useState} from "react";
import {Col, Row} from "react-flexbox-grid";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import {columns, formatDataTable, getAllAlertNotifications} from "./alert_notification_logic";
import OneColumn from "../layout/containers/OneColumn";
import LoaderContext from "../layout/shared/loader_context";
import {Card, Container} from "react-bootstrap";
import Cookies from "js-cookie";

const AlertNotificationTable = props => {
    const [notificationList, setNotificationList] = useState([]);
    const [updateReadCheck, setUpdateReadCheck] = useState(false);
    const Loader = useContext(LoaderContext);
    const layout = JSON.parse(Cookies.get("layout_02"));

    let pageConfig = {
        active: '',
        title: ''
    };

    useEffect(() => {
        (async function () {
            Loader.show(true);
            const notifications = await getAllAlertNotifications();
            const listDataFormatAlert = await formatDataTable(
                notifications,
                setUpdateReadCheck,
                updateReadCheck
            );
            setNotificationList(listDataFormatAlert);
            Loader.show(false);
        })();
    }, [updateReadCheck]);

    return (
        <OneColumn {...props} pageConfig={pageConfig} hideTopMenu={true} fluid={true} noTitle={true}>
            <Container
                fluid={true}
                className="bg-size-cover"
                style={{
                    minHeight: '76vh',
                    backgroundImage: layout && layout['app_background_session'] ? 
                        `url(${layout['app_background_session']})` :
                        "url(" + process.env.PUBLIC_URL + "/img/login_wallpaper.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center"
                }}
            >
                <Container>
                    <Row>
                        <Col
                            sm={12}
                            md={{span: 10, offset: 1}}
                            className="mY-30"
                        >
                            <Card className="mY-40@sm+">
                                <Card.Body>
                                    <h5 className="text-center">Buzon de alertas</h5>
                                    <ToolkitProvider
                                        keyField="id"
                                        data={notificationList}
                                        columns={columns}
                                        search
                                    >
                                        {props => (
                                            <Row>
                                                <Col xs={12}>
                                                    <div className="table-responsive">
                                                        <div style={{minWidth: "40rem"}}>
                                                            <BootstrapTable
                                                                {...props.baseProps}
                                                                striped
                                                                hover
                                                                condensed
                                                                responsive
                                                                pagination={paginationFactory()}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={2}/>
                                            </Row>
                                        )}
                                    </ToolkitProvider>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </OneColumn>
    );
};

export default AlertNotificationTable;
