import Cookies from "js-cookie";
import { destroyCookies } from "../login/login_logic";
import { NewClassRequest } from "../classes/NewClassRequest";
import { NotificationManager } from "react-notifications";

/**********************************************************FORGOT PASSWORD************************************************************************/
export const getUserData = async () => {
  const { token_02, uid_02, client_02, userId_02 } = Cookies.get();

  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/users/${userId_02}`,
    "get",
    null,
    {
      uid_name: uid_02,
      access_token_name: token_02,
      client_name: client_02
    }
  );
  const getDataUser = await request.executeRequest();

  if (getDataUser.code === 200) {
    const user = getDataUser.response.user;

    return {
      code: getDataUser.code,
      curp: user.curp,
      paternal_surname: user.paternal_surname,
      maternal_surname: user.maternal_surname,
      names: user.names,
      rfc: user.rfc,
      email: user.email,
      phone: user.phone
    };
  }
};
/***********************************************************************************************************************************************/

export const UpdateProfileRequest = async (form, setErrors) => {
  const { userId_02, username_02, domain_name_02 } = Cookies.get();
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/users/${userId_02}`,
    "patch",
    null,
    {
      email: form.email,
      username: username_02,
      names: form.names,
      paternal_surname: form.paternal_surname,
      maternal_surname: form.maternal_surname,
      app_id: "02",
      active: "1",
      roles: [],
      domain_name: domain_name_02,
      phone: form.phone
    }
  );
  const getUpdateProfileData = await request.executeRequest();

  if (getUpdateProfileData.code === 200) {

    if ( getUpdateProfileData.response.error && typeof getUpdateProfileData.response.message === "object" ) {

      setErrors( getUpdateProfileData.response.message );
      NotificationManager.error("Existen errores que impiden continuar", "Revisa tus datos: ");
    }
    else {

      destroyCookies();
      setTimeout(() => {
        NotificationManager.success(
          "La información del usuario se actualizo correctamente."
        );
      }, 500);
    }
  }
  else {
    if ( getUpdateProfileData.code === 422 && getUpdateProfileData.response && getUpdateProfileData.response.error ) {
      const errors = getUpdateProfileData.response.error;
      setErrors(errors);
      NotificationManager.error("Existen errores que impiden continuar", "Error");
    }
  }
};
/***********************************************************************************************************************************************/
