import React, { useContext, useEffect, useState } from "react";
import { Card, Form, Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import {finisRegistration, initForm, renderErrorsList, renderClassesToErrors} from "./confirm_registration_logic";
import GroupButtonsForm from "../helpers_components/GroupSubmitFormButtons";
import InformationTable from "./InformationTable";
import {
  getUserData,
  UpdateProfileRequest
} from "../edit_profile/edit_profile_logic";
import LoaderContext from "../layout/shared/loader_context";
import {
  termsRequest,
  ModalTermsAndConditions
} from "../register/register_logic";
import DomainContext from "../domains/domainPathContext";

const PhysicalPerson = props => {
  const [form, setForm] = useState(initForm);
  const [referenceEmail, setReferenceEmail] = useState("");
  const [changeEmailOnEdit, setChangeEmailOnEdit] = useState(false);
  const Loader = useContext(LoaderContext);
  const [handleTermsModal, setHandleTermsModal] = useState(false);
  const [termsText, setTermsText] = useState(false);
  const [termsTitle, setTermsTitle] = useState("");
  const [canShowModal, setCanShowModal] = useState(true);
  const subdomain = useContext(DomainContext);
  const [errors, setErrors] = useState({});

  const onSubmitFormHandler = async e => {
    let formAtDom = e.currentTarget;
    e.preventDefault();
    Loader.show(true);

    //VALIDATE IF IS EDIT PROFILE
    if (props.editProfile) {
      await UpdateProfileRequest(form, setErrors);
    } else {
      //ELSE IS A NEW REGISTER
      await finisRegistration(form, props.token, props, subdomain, setErrors, formAtDom);
    }
    Loader.show(false);
  };

  //WHEN COMPONENT RENDER CAUSE USEEFECT FOR CALL API AND POPULATE FORM
  useEffect(() => {
    (async function() {
      if (props.editProfile) {
        Loader.show(true);
        try {
          const response = await getUserData();
          if (response.code === 200) {
            setReferenceEmail(response.email);
            setForm(response);
          }
          Loader.show(false);
        } catch (e) {
          Loader.show(false);
        }
      }
      else await termsRequest(setTermsTitle, setTermsText, setCanShowModal);
    })();
  }, []);

  const handleChangeForm = event => {
    if (event.target.name === "email") {
      if (event.target.value !== referenceEmail) {
        setChangeEmailOnEdit(true);
      } else {
        setChangeEmailOnEdit(false);
      }
    }
    Object.keys(errors).forEach((error) => {
      if (error=== event.target.name) {
        delete errors[event.target.name];
      }
    });
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const ModalToTermsAndConditions = ModalTermsAndConditions(
    handleTermsModal,
    setHandleTermsModal,
    termsText,
    termsTitle
  );

  return (
    <Card className="w-100 mY-40@sm+">
      <Card.Header>
        <Container>
          <Row>
            <Col xs={12} className="pT-20 pB-10 pT-30@sm+ pB-20@sm+">
              <h3 className="mB-20 text-center">
                {props.editProfile
                  ? "Edición de usuario"
                  : "Confirmación de Registro"}
              </h3>
            </Col>
          </Row>
        </Container>
      </Card.Header>
      <Card.Body>
        <div className="account-wall">
          { !props.editProfile ? 
              <InformationTable
                {...props}
                show={props.editProfile ? false : true}
              /> :
              ""
          }
          <Form
            onSubmit={onSubmitFormHandler}
            style={{ padding: "15px", margin: "0 auto" }}
          >
            <Form.Group controlId="formBasicCurp">
              <Form.Label>
                <b>* C.U.R.P</b>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Clave Única de Registro de Población"
                required={props.editProfile ? false : true}
                autoFocus
                name="curp"
                readOnly={props.editProfile ? true : false}
                onChange={handleChangeForm}
                value={form.curp}
                className={ renderClassesToErrors(errors, "curp") }
              />
                { renderErrorsList(errors, "curp") }
              <small>
                ¿No sabes tu CURP?, consúltala haciendo clic
                <a
                  href="https://consultas.curp.gob.mx/CurpSP/gobmx/inicio.jsp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  AQUI
                </a>
              </small>
            </Form.Group>

            <Form.Group
              controlId="formBasicEmail"
              style={
                props.editProfile ? { display: "block" } : { display: "none" }
              }
            >
              <Form.Label>
                <b>* Email</b>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Correo electronico"
                name="email"
                onChange={
                  props.editProfile ? handleChangeForm : e => e.target.value
                }
                value={props.editProfile ? form.email : ""}
                className={ renderClassesToErrors(errors, "email") }
              />
              { renderErrorsList(errors, "email") }

              <Form.Text
                className="text-muted"
                style={
                  changeEmailOnEdit
                    ? {
                        display: "block",
                        background: "#ffbb33",
                        textAlign: "start",
                        padding: "5px"
                      }
                    : { display: "none" }
                }
              >
                Si actualizas el correo electronico deberas realizar el paso de
                confirmación posteriormente al guardar cambios.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicNombre">
              <Form.Label>
                <b>* Nombre(s)</b>
              </Form.Label>
              <OverlayTrigger
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    Escribe tu(s) nombre(s) tal y como aparece en tu identificación oficial. <br/>
                    Utiliza únicamente caracteres alfabéticos y acentos. 
                  </Tooltip>
                }
              >
                <Form.Control
                  type="text"
                  required={props.editProfile ? false : true}
                  name="names"
                  onChange={handleChangeForm}
                  value={form.names}
                  className={ renderClassesToErrors(errors, "names") }
                />
              </OverlayTrigger>
              { renderErrorsList(errors, "names") }
            </Form.Group>

            <Form.Group controlId="formBasicApellidoPaterno">
              <Form.Label>
                <b>* Apellido paterno</b>
              </Form.Label>
              <OverlayTrigger
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    Escribe tu apellido paterno tal y como aparece en tu identificación oficial.<br/>
                    Utiliza únicamente caracteres alfabéticos y acentos. 
                  </Tooltip>
                }
              >
                <Form.Control
                  type="text"
                  required={props.editProfile ? false : true}
                  name="paternal_surname"
                  onChange={handleChangeForm}
                  value={form.paternal_surname}
                  className={ renderClassesToErrors(errors, "paternal_surname") }
                />
              </OverlayTrigger>
              { renderErrorsList(errors, "paternal_surname") }
            </Form.Group>

            <Form.Group>
              <Form.Label>
                <b>Apellido materno</b>
              </Form.Label>
              <OverlayTrigger
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    Escribe tu apellido materno tal y como aparece en tu identificación oficial.<br/>
                    Utiliza únicamente caracteres alfabéticos y acentos. 
                  </Tooltip>
                }
              >
                <Form.Control
                  type="text"
                  name="maternal_surname"
                  onChange={handleChangeForm}
                  defaultValue={form.maternal_surname ? form.maternal_surname : ""}
                  className={ renderClassesToErrors(errors, "maternal_surname") }
                />
              </OverlayTrigger>
              { renderErrorsList(errors, "maternal_surname") }
            </Form.Group>

            <Form.Group controlId="formBasicRFC">
              <Form.Label>
                <b>RFC</b>
              </Form.Label>
              <Form.Control
                type="text"
                name="rfc"
                readOnly={props.editProfile ? true : false}
                placeholder="Registro Federal de Contribuyentes"
                onChange={handleChangeForm}
                value={form.rfc ? form.rfc : ""}
                className={ renderClassesToErrors(errors, "rfc") }
              />
              { renderErrorsList(errors, "rfc") }
            </Form.Group>

            <Form.Group controlId="formBasicPhone">
              <Form.Label>
                <b>* Teléfono</b>
              </Form.Label>
              <Form.Control
                type="text"
                name="phone"
                required
                placeholder="Agrega un número telefónico (10 dígitos)"
                onInput={(e) => e.target.value = Number(
                  Math.max(
                    0, parseInt(e.target.value,10) 
                  ).toString().slice(0,10)) || ""}
                onChange={handleChangeForm}
                value={form.phone ? form.phone : ""}
                className={ renderClassesToErrors(errors, "phone") }
              />
              { renderErrorsList(errors, "phone") }
            </Form.Group>

            <div
              className="mT-20"
              style={
                props.editProfile ? { display: "none" } : { display: "block" }
              }
            >
              <p>
                * Al registrarme, aseguro haber leído y aceptado los{" "}
                <span
                  onClick={
                    canShowModal
                      ? () => {
                          setHandleTermsModal(true);
                        }
                      : null
                  }
                  className="cursor-pointer px-1"
                >
                  términos y condiciones
                </span>
                de uso del sistema
              </p>
            </div>

            <div className="pY-20">
              <GroupButtonsForm
                primaryText="Guardar"
                secondaryText="Cancelar"
                urlToCancel={`/${subdomain}/home`}
              />
            </div>
          </Form>
        </div>
      </Card.Body>
      <>{ModalToTermsAndConditions}</>
    </Card>
  );
};

export default PhysicalPerson;
