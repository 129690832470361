import React from "react";
import Cookies from "js-cookie";
import { Request } from "../../classes/Request";
import { Card } from "react-bootstrap";

export const getObservations = async (
  stage_id,
  element,
  setFormattObservations
) => {
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/observation/get_comment/${stage_id}/${element.id}?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const getObservationsData = await request.executeRequest();
  if (getObservationsData.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE TALOBILLO
    const observations = await formattObservations(
      getObservationsData.data.data.data.observation
    );
    setFormattObservations(observations);
  } else {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE TALOBILLO
    setFormattObservations(null);
  }
};

/**********************************************************FORGOT PASSWORD************************************************************************/
export const saveObservation = async (
  observation,
  setShow,
  setObservations,
  element,
  stage
) => {
  const { userId_02 } = Cookies.get();
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/observation/create_comment`,
    "post",
    null,
    {
      stage_id: stage,
      field_id: element.id,
      user_id: userId_02,
      description: observation,
      token: process.env.REACT_APP_TOKEN
    }
  );
  const saveObservationData = await request.executeRequest();
  if (saveObservationData.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE TALOBILLO
    setShow(false);
    setObservations("");
  } else {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE TALOBILLO
    console.log(saveObservationData);
  }
};
/***********************************************************************************************************************************************/

export const formattObservations = async observations => {
  return (
    <Card>
      <Card.Body>
        <Card.Text>{observations.description}</Card.Text>
      </Card.Body>
      <Card.Footer>
        <small className="text-muted">Updated {observations.updated_at}</small>
      </Card.Footer>
    </Card>
  );
};
/***********************************************************************************************************************************************/
