import React from "react";
import { Request } from "../../classes/Request";
import { Modal, Button } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import Cookies from 'js-cookie';

export const initStateFooter = {
  app_city: "",
  app_colony: "",
  app_facebook_url_image: "",
  app_favicon_url_image: "",
  app_footer_logo_url_image: "",
  app_header_logo_url_image: "",
  app_number: "",
  app_phones: "",
  app_postal_code: "",
  app_state: "",
  app_street: "",
  app_title_page: "",
  app_twitter_url_image: "",
  app_youtube_url_image: "",
  id: "",
  user_type: ""
};

/**********************************************************FORGOT PASSWORD************************************************************************/
export const getFooterData = async setInitForm => {
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/app_config?token=${process.env.REACT_APP_TOKEN
    }`,
    "get",
    null,
    {}
  );
  const getFooterDataConfig = await request.executeRequest(false);
  if (getFooterDataConfig.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    setInitForm(getFooterDataConfig.data.data.data.config);
  }
};
/***********************************************************************************************************************************************/

export const privacyRequest = async (setPrivacyModalElements, setShowModal) => {
  const { domain_id_02 } = Cookies.get();
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/terms/${domain_id_02}/privacy?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const token = btoa(process.env.REACT_APP_TOKEN_WITHOUT_AUTHENTICATION);
  const privacyRequestData = await request.executeRequest(false, token);
  if (privacyRequestData.code === 200) {

    setPrivacyModalElements(
      <>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {privacyRequestData.data.data.data.content.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ 'maxHeight': 'calc(100vh - 210px)', 'overflowY': 'auto' }}>
          <div
            dangerouslySetInnerHTML={{ __html: privacyRequestData.data.data.data.content.description }}
            style={{ maxHeight: "60vh", overflowY: "scroll" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowModal(false)}>Aceptar</Button>
        </Modal.Footer>
      </>
    );
    setShowModal(true);
  }
  else {
    setShowModal(false);

    NotificationManager.warning(
      "No fue encontrado ningún aviso de privacidad"
    );
  }
};
