/***********************************IMPORT LIBRARIES*************************************************** */
import React, { useState, useEffect } from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import Cookies from "js-cookie";
import { NotificationContainer } from "react-notifications";

/*************************************ROUTE COMPONENTS****************************************** */
import Login from "./components/login/Login";
import Home from "./components/home/Home";
import Register from "./components/register/Register";
import ForgotPassword from "./components/forgot_password/ForgotPassword";
import NotFound from "./components/error_page/404";
import ChangePassword from "./components/change_password/ChangePassword";
import FinishRegistration from "./components/confirm_registration/FinishRegistration";
import RecoveryPassword from "./components/forgot_password/RecoveryPassword";
import EditProfile from "./components/edit_profile/EditProfile";
import BoostrapTable from "./components/helpers_components/BootstrapTable";
import Formalities from "./components/formalities/Formalities";
import Procedure from "./components/formalities/Procedure";
import RequestOffline from "./components/request_offline/RequestOffline";
import ProcessList from "./components/formalities/ProcessList";
import AlertNotificationTable from "./components/alert_notification_table/AlertNotificationTable";
import RenderForms from "./components/formalities/star_procedure/RenderForms";
import AvatarConfig from "./components/avatar_config/AvatarConfig";
import ConfirmationEmail from "./components/confirmation_email/ConfirmationEmail";
import ErrorPage from "./components/helpers_components/ErrorPage";
import NotificationListContext from "./components/alert_notification_table/notification_context";
import { getAllAlertNotifications } from "./components/alert_notification_table/alert_notification_logic";
import ProcedureList from "./components/procedure_list/ProcedureList";
import LoaderContext from "./components/layout/shared/loader_context";
import ErrorCatcher from "./components/error_page/500";
import { domainRequest } from "./domainRequest";
import { getLayoutData } from "./app_login";
import DomainPath from "./components/domains/domainPathContext";
import LoaderPage from "./components/load_page/LoaderPage";
import Contact from "./components/contact/Contact";
import GlobalStateProvider from "./context/globalState/GlobalStateContext";

const App = props => {
  let subdomain = window.location.pathname.split("/");
  const isAuth = Cookies.get();
  const [countNotifications, setCountNotifications] = useState(0);
  const [needUpdateCount, setNeedUpdateCount] = useState(false);
  const [statusLoader, setLoaderStatus] = useState(false);
  const [domainPath, setDomainPath] = useState(subdomain[1]);
  const [actionTo, setActionTo] = useState("Loader");

  const updateCountHandler = (state, sumOrSubtract) => {
    //THIS FUNCTION ALLOWS CHANGE THE STATE WHEN THE CHECKBOX MARK AS READ AND RENDER THE COMPONENT FOR THE COUNTER ALERT UPDATE
    if (sumOrSubtract === "subtract") {
      setCountNotifications(countNotifications - 1);
    } else if (sumOrSubtract === "sum") {
      setCountNotifications(countNotifications + 1);
    }
    setNeedUpdateCount(state);
  };

  useEffect(() => {
    (async function() {
      if (isAuth.isAuth_02) {
        const response = await getAllAlertNotifications();
        setCountNotifications(response.length);
      }
    })();
  },[]);

  useEffect(() => {
    (async function() {
      const domainName = Cookies.get("domain_name_02")
        ? Cookies.get("domain_name_02")
        : null;
      let action; 

      // EXISTE DOMINIO CORRECTO Y YA SETEADO EN COOKIES
      if (domainName) {
        if (window.location.pathname !== '/contacto') {
          // REVISAMOS SI ES IGUAL EL DOMINIO QUE ESTA EN LA URL AL QUE ESTA EN COOKIES
          if (domainName === subdomain[1]) {
            // SI ES IGUAL SIGNIFICA QUE SE ESTA TENIENDO UN COMPORTAMIENTO NORMAL 
            // QUE SIGNIFICA QUE EL USUARIO NO HA CAMBIADO EL DOMINIO EN EL QUE 
            // SE AUTENTICO
            setActionTo("Normal");
          }
          // SIN EMBARGO SI EL DOMINIO DE LA URL Y EL DOMINIO EN COOKIES ES DISTINTO
          // SIGNIFICA QUE SE ESTA CAMBIANDO EL DOMINIO EN EL CUAL SE HABIAN AUTENTICADO 
          else {
            // AHORA DEBEMOS DE INVOCAR LA FUNCION domainRequest LA CUAL REVISARA:
            // - si el dominio es valido
            // - si el usuario esta autenticado 
            // Y NOS REGRESARA UNA ACCION A REALIZAR YA SEA UNA REDIRECCION AL LOGIN DEL NUEVO DOMINIO
            // O UN NOTFOUND DEBIDO A QUE EL DOMINIO QUE SE ESCRIBIO NO ES VALIDO
            action = await domainRequest( subdomain[1], setDomainPath );
            await getLayoutData();

            setActionTo(action);
          }
        }
      }
      // SI NO HAY DOMINIO EN COOKIES ES PROBABLE QUE SE ESTE ENTRANDO POR PRIMERA VEZ
      // ASI QUE DEBEMOS DE INVOCAR LA FUNCION domainRequest PARA QUE NOS REVISE:
      // - si el dominio es valido
      // - si el usuario esta autenticado 
      // UNA VEZ REVISADO ESTO NOS DEVOLVERA UNA ACCION YA SEA UN LOGIN AL DOMINIO CORRESPONDIENTE
      // O UN NOTFOUND DEBIDO A QUE EL DOMINIO NO ES VALIDO
      else {
        if (window.location.pathname !== '/contacto') {
          action = await domainRequest( subdomain[1], setDomainPath );
          await getLayoutData();

          setActionTo(action);
        }
      }
    })();
    // ESTA FUNCION SE EJECUTARA SOLO UNA VEZ CUANDO domainName EN COOKIES Y EL subdomain[1] QUE ESTA EN LA URL 
    // SEAN DISTINTOS, EN LOS DEMAS CASOS SE EJECUTARA LAS VECES QUE SEA NECESARIA.
  }, [ Cookies.get("domain_name_02") && Cookies.get("domain_name_02") !== subdomain[1] ? null : subdomain ]);

  return (
    <ErrorCatcher>
      <BrowserRouter>
        <GlobalStateProvider>
        <DomainPath.Provider value={domainPath}>
          <NotificationListContext.Provider
            value={{
              count: countNotifications,
              updateCount: updateCountHandler,
              requireUpdate: needUpdateCount
            }}
          >
            <LoaderContext.Provider
              value={{
                status: statusLoader,
                show: status => {
                  setLoaderStatus(status);
                }
              }}
            >
              <NotificationContainer />
              <LastLocationProvider>
                <Switch>
                  <Route path="/contacto" exact component={Contact}/>
                  <Route
                    exact
                    path="/:subdomain"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (isAuth.isAuth_02) {
                          if (Cookies.get("current_redir_02")) {
                            const redirectTo = Cookies.get("current_redir_02");
                            Cookies.remove("current_redir_02");
                            return <Redirect to={redirectTo} />
                          }
                          return <Home {...props} />;
                        } else {
                          return <Login {...props} />;
                        }
                      } else if ( actionTo === "Loader" ) {
                          return <LoaderPage/>;
                      }
                      else if ( actionTo === "NotFound" ) {
                        setDomainPath(Cookies.get("domain_name_02"));
                        return <NotFound/>;
                      }
                      else {
                        return <Redirect to={`/${Cookies.get("domain_name_02")}`} />;
                      }
                    }}
                  />
                  <Route
                    path="/:subdomain/forgot-password"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (isAuth.isAuth_02) {
                          return <Home {...props} />;
                        } else {
                          return <ForgotPassword {...props} />;
                        }
                      } else if ( actionTo === "Loader" ) {
                        return <LoaderPage/>;                      
                      }
                      else if ( actionTo === "NotFound" ) {
                        setDomainPath(Cookies.get("domain_name_02"));
                        return <NotFound/>;
                      }
                      else {
                        return <Redirect to={`/${Cookies.get("domain_name_02")}`} />;
                      }
                    }}
                  />
                  <Route
                    path="/:subdomain/recover_password:reset_password_token?"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (isAuth.isAuth_02) {
                          return <Home {...props} />;
                        } else {
                          return <RecoveryPassword {...props} />;
                        }
                      } else if ( actionTo === "Loader") {
                        return <LoaderPage />;                      
                      }
                      else if ( actionTo === "NotFound" ) {
                        setDomainPath(Cookies.get("domain_name_02"));
                        return <NotFound />;
                      }
                      else {
                        return <Redirect to={`/${Cookies.get("domain_name_02")}`} />;
                      }
                    }}
                  />
                  <Route
                    exact
                    path="/:subdomain/home"
                    render={props => {
                      if ( actionTo === "Normal") {
                        if (isAuth.isAuth_02) {
                          if (Cookies.get("current_redir_02")) {
                            const redirectTo = Cookies.get("current_redir_02");
                            Cookies.remove("current_redir_02");
                            return <Redirect to={redirectTo} />
                          }
                          return <Home {...props} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_02"));
                          setActionTo("Normal");
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_02")}`} />
                          );
                        }
                      } else if ( actionTo === "Loader") {
                        return <LoaderPage/>;
                      }
                      else if( actionTo === "NotFound" ) {
                        setDomainPath(Cookies.get("domain_name_02"));
                        return <NotFound />;
                      }
                      else {
                        return <Redirect to={`/${Cookies.get("domain_name_02")}`} />;
                      }
                    }}
                  />

                  <Route
                    path="/:subdomain/edit_profile"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (isAuth.isAuth_02) {
                          return <EditProfile {...props} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_02"));
                          setActionTo("Normal");
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_02")}`} />
                          );
                        }
                      } else if ( actionTo === "Loader" ) {
                        return <LoaderPage/>;
                      }
                      else if( actionTo === "NotFound" ) {
                        setDomainPath(Cookies.get("domain_name_02"));
                        return <NotFound />;
                      }
                      else {
                        return <Redirect to={`/${Cookies.get("domain_name_02")}`} />;
                      }
                    }}
                  />

                  <Route path="/products" component={BoostrapTable} />
                  <Route
                    exact
                    path="/:subdomain/confirmation_email:token?"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (isAuth.isAuth_02) {
                          return <Home {...props} />;
                        } else {
                          return <ConfirmationEmail {...props} />;
                        }
                      } else if ( actionTo === "Loader" ) {
                        return <LoaderPage/>;
                      }
                      else if( actionTo === "NotFound" ) {
                        setDomainPath(Cookies.get("domain_name_02"));
                        return <NotFound />;
                      }
                      else {
                        return <Redirect to={`/${Cookies.get("domain_name_02")}`} />;
                      }
                    }}
                  />
                  <Route
                    exact
                    path="/:subdomain/request_offline"
                    render={props => {
                      if (actionTo) {
                        if (isAuth.isAuth_02) {
                          return <Home {...props} />;
                        } else {
                          return <RequestOffline {...props} />;
                        }
                      } else {
                        setDomainPath(Cookies.get("domain_name_02"));
                        return <NotFound />;
                      }
                    }}
                  />

                  <Route
                    path="/:subdomain/procedure-list"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (isAuth.isAuth_02) {
                          return <ProcedureList history={props.history} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_02"));
                          setActionTo("Normal");
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_02")}`} />
                          );
                        }
                      } else if ( actionTo === "Loader" ) {
                        return <LoaderPage/>;
                      }
                      else if( actionTo === "NotFound" ) {
                        setDomainPath(Cookies.get("domain_name_02"));
                        return <NotFound />;
                      }
                      else {
                        return <Redirect to={`/${Cookies.get("domain_name_02")}`} />;
                      }
                    }}
                  />

                  <Route
                    path="/:subdomain/change-password"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (isAuth.isAuth_02) {
                          return <ChangePassword {...props} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_02"));
                          setActionTo("Normal");
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_02")}`} />
                          );
                        }
                      } else if ( actionTo === "Loader" ) {
                        return <LoaderPage/>;
                      }
                      else if( actionTo === "NotFound" ) {
                        setDomainPath(Cookies.get("domain_name_02"));
                        return <NotFound />;
                      }
                      else {
                        return <Redirect to={`/${Cookies.get("domain_name_02")}`} />;
                      }
                    }}
                  />

                  <Route
                    path="/:subdomain/alert-notifications"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (isAuth.isAuth_02) {
                          return <AlertNotificationTable {...props} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_02"));
                          setActionTo("Normal");
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_02")}`} />
                          );
                        }
                      } else if ( actionTo === "Loader" ) {
                        return <LoaderPage/>;
                      }
                      else if( actionTo === "NotFound" ) {
                        setDomainPath(Cookies.get("domain_name_02"));
                        return <NotFound />;
                      }
                      else {
                        return <Redirect to={`/${Cookies.get("domain_name_02")}`} />;
                      }
                    }}
                  />
                  <Route
                    path="/:subdomain/formalities"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (isAuth.isAuth_02) {
                          return <Formalities history={props.history} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_02"));
                          setActionTo("Normal");
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_02")}`} />
                          );
                        }
                      } else if ( actionTo === "Loader" ) {
                        return <LoaderPage />;
                      }
                      else if( actionTo === "NotFound" ) {
                        setDomainPath(Cookies.get("domain_name_02"));
                        return <NotFound />;
                      }
                      else {
                        return <Redirect to={`/${Cookies.get("domain_name_02")}`} />;
                      }
                    }}
                  />
                  <Route
                    path="/:subdomain/render_form"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (isAuth.isAuth_02) {
                          return <RenderForms {...props} history={props.history} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_02"));
                          setActionTo("Normal");
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_02")}`} />
                          );
                        }
                      } else if ( actionTo === "Loader" ) {
                        return <LoaderPage />;
                      }
                      else if( actionTo === "NotFound" ) {
                        setDomainPath(Cookies.get("domain_name_02"));
                        return <NotFound />;
                      }
                      else {
                        return <Redirect to={`/${Cookies.get("domain_name_02")}`} />;
                      }
                    }}
                  />

                  <Route
                    path="/:subdomain/error_page"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (isAuth.isAuth_02) {
                          return <ErrorPage {...props} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_02"));
                          setActionTo("Normal");
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_02")}`} />
                          );
                        }
                      } else if ( actionTo === "Loader" ) {
                        return <LoaderPage />;
                      }
                      else if( actionTo === "NotFound" ) {
                        setDomainPath(Cookies.get("domain_name_02"));
                        return <NotFound />;
                      }
                      else {
                        return <Redirect to={`/${Cookies.get("domain_name_02")}`} />;
                      }
                    }}
                  />

                  <Route
                    path="/:subdomain/getProcess"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (isAuth.isAuth_02) {
                          return <ProcessList history={props.history} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_02"));
                          setActionTo("Normal");
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_02")}`} />
                          );
                        }
                      } else if ( actionTo === "Loader" ) {
                        return <LoaderPage />;
                      }
                      else if( actionTo === "NotFound" ) {
                        setDomainPath(Cookies.get("domain_name_02"));
                        return <NotFound />;
                      }
                      else {
                        return <Redirect to={`/${Cookies.get("domain_name_02")}`} />;
                      }
                    }}
                  />

                  {/* <Route
                    render={props =>
                      props.location.state ? (
                        <NotificationOffline location={props.location} />
                      ) : (
                        <NotFound />
                      )
                    }
                    path="/notification_offline"
                  /> */}

                  <Route
                    path="/:subdomain/register"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (isAuth.isAuth_02) {
                          return <Home {...props} />;
                        } else {
                          return <Register {...props} />;
                        }
                      } else if ( actionTo === "Loader" ) {
                        return <LoaderPage />;
                      }
                      else if( actionTo === "NotFound" ) {
                        setDomainPath(Cookies.get("domain_name_02"));
                        return <NotFound />;
                      }
                      else {
                        return <Redirect to={`/${Cookies.get("domain_name_02")}`} />;
                      }
                    }}
                  />

                  <Route
                    path="/:subdomain/finish_registration:token?:reg?"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (isAuth.isAuth_02) {
                          return <Home {...props} />;
                        } else {
                          return <FinishRegistration {...props} />;
                        }
                      } else if ( actionTo === "Loader" ) {
                        return <LoaderPage />;
                      }
                      else if( actionTo === "NotFound" ) {
                        setDomainPath(Cookies.get("domain_name_02"));
                        return <NotFound />;
                      }
                      else {
                        return <Redirect to={`/${Cookies.get("domain_name_02")}`} />;
                      }
                    }}
                  />

                  <Route
                    path="/:subdomain/procedure/:id"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (isAuth.isAuth_02) {
                          return <Procedure props={props} />;
                        } else {
                          Cookies.set("current_redir_02", props.location.pathname);
                          setDomainPath(Cookies.get("domain_name_02"));
                          setActionTo("Normal");
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_02")}`} />
                          );
                        }
                      } else if ( actionTo === "Loader" ) {
                        return <LoaderPage />;
                      }
                      else if( actionTo === "NotFound" ) {
                        setDomainPath(Cookies.get("domain_name_02"));
                        return <NotFound />;
                      }
                      else {
                        return <Redirect to={`/${Cookies.get("domain_name_02")}`} />;
                      }
                    }}
                  />
                  <Route
                    path="/:subdomain/avatar_config"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (isAuth.isAuth_02) {
                          return <AvatarConfig {...props} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_02"));
                          setActionTo("Normal");
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_02")}`} />
                          );
                        }
                      } else if ( actionTo === "Loader" ) {
                        return <LoaderPage />;
                      }
                      else if( actionTo === "NotFound" ) {
                        setDomainPath(Cookies.get("domain_name_02"));
                        return <NotFound />;
                      }
                      else {
                        return <Redirect to={`/${Cookies.get("domain_name_02")}`} />;
                      }
                    }}
                  />
                  <Route component={NotFound} />
                </Switch>
              </LastLocationProvider>
            </LoaderContext.Provider>
          </NotificationListContext.Provider>
        </DomainPath.Provider>
        </GlobalStateProvider>
      </BrowserRouter>
    </ErrorCatcher>
  );
};

export default App;
