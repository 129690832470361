import React, { useState } from 'react';
import GoogleRecaptcha from '../helpers_components/GoogleRecaptcha';
import _ from 'lodash';
import { NewClassRequest } from '../classes/NewClassRequest';
import { NotificationManager } from 'react-notifications';
import { Navbar, Nav, NavDropdown, Container, Modal } from "react-bootstrap";
import '../assets/css/main.css';
import '../assets/css/fonts.css';
import { privacyRequest } from '../layout/shared/footer_logic';

const Contact = () => {
  return (
    <>
      <body>
        <ContactHeader />
        <div id="fixed-contact" className="container mt-5">
          <ContactForm />
        </div>
      </body>
      <footer>
        <ContactFooter />
      </footer>
    </>
  );
}

const ContactHeader = () => {
  return (
    <>
      <div id="fondo-menu" style={{ backgroundColor: "#d7eff9" }}>
        <Container style={{ paddingTop: 5, backgroundColor: "#d7eff9" }}>
          <div className="menu">
            <Navbar expand="lg" style={{ padding: 0, margin: 0, backgroundColor: "#d7eff9" }}>
              <Navbar.Brand>
                <a href="/inicio/index.html">
                  <img alt="Gobierno de Jalisco" style={{ width: '95%', height: '100%' }} className="img-fluid" src={process.env.PUBLIC_URL + "img/contacto/headers/menu-ventanilla-digital-logo.png"} />
                </a>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav" style={{ paddingRight: 60 }} className="justify-content-end">
                <Nav className="me-auto">
                  <Nav.Link className="nav-link-contact menu-link" href="/inicio/index.html">Inicio</Nav.Link>
                  <Nav.Link className="nav-link-contact menu-link" href="/inicio/tramites.html">Trámites</Nav.Link>
                  <Nav.Link className="nav-link-contact menu-link" href="/contacto">Contacto</Nav.Link>
                  <Nav.Link className="nav-link-contact menu-link" href={process.env.REACT_APP_DEFAULT_DOMAIN + "/register"}>Registrar</Nav.Link>
                  <Nav.Link className="nav-link-contact menu-link" href={process.env.REACT_APP_DEFAULT_DOMAIN}>Ingresar</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </Container>
      </div>

      <div id="fondo-contacto">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 pt-5">
              <div className="titulos pt-5">Contacto</div>
            </div>
            <div className="col-md-6 col-sm-12">
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const ContactForm = () => {
  const [recaptcha, setRecaptcha] = useState("");
  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState({});
  const [sending, setSending] = useState(false);

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const validateForm = () => {
    const requiredFields = [
      'name',
      'email',
      'comment',
    ];

    const formErrors = requiredFields
      .filter(r => formData[r] === undefined)
      .reduce((map, r) => ({ ...map, [r]: ['El campo es requerido'] }), {});

    if (!recaptcha || recaptcha.trim().length <= 0) {
      formErrors.recaptcha = ['El campo es requerido'];
    }

    if (!validateEmail(formData.email || '')) {
      formErrors.email = ['Formato de correo electrónico no válido'];
    }

    setErrors(formErrors);

    return _.isEmpty((formErrors));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setSending(true);

    const request = new NewClassRequest(
      `${process.env.REACT_APP_URL_API_LARAVEL}/contacts`,
      "post",
      null,
      {
        ...formData,
        recaptcha
      }
    );

    const response = await request.executeRequest();

    if (response.code !== 200) {
      setSending(false);
      return NotificationManager.error("Error al enviar el mensaje");
    }

    NotificationManager.success('Mensaje enviado');
    setFormData('');
    setSending(false);
  }

  const renderErrorsList = (errors, inputName) => {
    if (errors && errors[inputName]) {
      const errorsList = errors[inputName].map((error, index) => {
        return (
          <li key={index} style={{ padding: "0px 25px 0px", color: "red", fontSize: ".8rem" }}>
            <small>
              {error}
            </small>
          </li>
        );
      })
      return errorsList;
    }
  }

  const renderHeaderForm = () => {
    return (<div className="row mb-5">
      <div className="col-md-1 text-center border-tramites">
        <img alt="Icono tramite" className="img-fluid" src={process.env.PUBLIC_URL + "img/contacto/contacto-icono.png"} />
      </div>
      <div className="col-md-11 textos">
        Para atender tu duda, comentario o sugerencia, por favor ingresa tu nombre y correo electrónico en el siguiente formulario:
      </div>
    </div>
    );
  }

  const renderSidebarForm = () => {
    return (
      <div className="col-md-5 mb-5">
        <div className="texto-introcontacto">¿Tienes algún comentario, duda o sugerencia sobre este sitio?</div><br />
        <div className="texto-contacto mb-3"><a href="mailto:contactoventanilladigital.sedeco@jalisco.gob.mx"><img alt="Viñeta cuadro" src={process.env.PUBLIC_URL + "img/contacto/cuadro.png"} /> contactoventanilladigital.sedeco@jalisco.gob.mx</a></div>
        <div className="texto-contacto mb-3"><a href="mailto:ventanilladigital.sedeco@jalisco.gob.mx"><img alt="Viñeta cuadro" src={process.env.PUBLIC_URL + "img/contacto/cuadro.png"} /> ventanilladigital.sedeco@jalisco.gob.mx</a></div>
      </div>
    );
  }

  const renderForm = () => {
    return (
      <div className="col-md-7">
        <form onSubmit={handleSubmit}>
          <div className="form-row textos">
            <div className="form-group col-md-6">
              <label htmlFor="InputNombre">Nombre*</label>
              <input
                type="text"
                className="form-control"
                id="InputNombre"
                value={formData.name || ''}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              />
              {renderErrorsList(errors, 'name')}
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="InputCorreo">Correo*</label>
              <input
                type="email"
                className="form-control"
                id="InputCorreo"
                value={formData.email || ''}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              />
              {renderErrorsList(errors, 'email')}
            </div>
          </div>
          <div className="form-group textos">
            <label htmlFor="dudaTextarea">Duda o comentario*</label>
            <textarea
              className="form-control"
              id="dudaTextarea"
              rows="5"
              value={formData.comment || ''}
              onChange={(e) => setFormData({ ...formData, comment: e.target.value })}
            ></textarea>
            {renderErrorsList(errors, 'comment')}
          </div>
          <div className="overflow-auto w-100">
            <GoogleRecaptcha
              validate={value => setRecaptcha(value)}
            />
            {renderErrorsList(errors, 'recaptcha')}
          </div>
          <button type="submit" className="btn btn-tramites mt-3" disabled={sending ? 'disabled' : ''}>ENVIAR</button>
        </form>
      </div>
    );
  }
  return (
    <>
      {renderHeaderForm()}
      <div className="row mb-5">
        {renderSidebarForm()}
        {renderForm()}
      </div>
    </>
  );
}

const ContactFooter = () => {

  const [privacyModalElements, setPrivacyModalElements] = useState({});
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="fondo_footer">
      <div className="container">
        <div className="row mb-4 border-footer">
          <div className="col-md-3">
            <div className="fontmont-800">.Inicio</div>
            <a href="/inicio/index.html">.¿Qué es?</a><br />
            <a href="/inicio/index.html#section2">.Beneficios</a><br />
            <a href="/inicio/index.html#fondo-bloque3">.¿Cómo se usa?</a><br />
            <a href="/inicio/index.html#section4">.Trámites Disponibles</a>
          </div>
          <div className="col-md-5">
            <div className="fontmont-800">.Preguntas Frecuentes</div>
            <a href="/inicio/faqs.html">.¿Qué otra información podrá encontrar en el portal de Ventanilla Digital Jalisco?</a><br />
            <a href="/inicio/faqs.html">.¿Puedo realizar pagos en línea?</a><br />
            <a href="/inicio/faqs.html">.¿Puedo hacer pagos en el oxxo o seven 7?</a><br />
            <a href="/inicio/faqs.html">.¿A donde me puedo comunicar para dar seguimiento a un trámite o servicio?</a>
          </div>
          <div className="col-md-4">
            <div className="fontmont-800">.Contacto</div>
            <div className="textodescriptivo-footer">¿Tienes algún comentario, duda o sugerencia sobre este sitio?</div><br />
            <a href="mailto:contactoventanilladigital.sedeco@jalisco.gob.mx ">contactoventanilladigital.sedeco@jalisco.gob.mx </a>
            <a href="mailto:ventanilladigital.sedeco@jalisco.gob.mx ">ventanilladigital.sedeco@jalisco.gob.mx</a>
          </div>
        </div>
        <div className="row mb-4 border-footer">
          <div className="col-md-3 pt-5">
          </div>
          <div className="col-md-4">
          </div>
          <div className="col-md-5">
            <div className="row text-center">
              <div className="col-6 pt-5">
                <img alt="Logo Ventanilla Digital" className="img-fluid" src={process.env.PUBLIC_URL + "img/contacto/footer/ventanilla-digital-logo-footer.png"} />
              </div>
              <div className="col-6">
                <a href="http://www.jalisco.gob.mx">
                  <img alt="Logo Twitter" className="img-fluid" src={process.env.PUBLIC_URL + "img/contacto/footer/Gobierno-jalisco-logo-footer.png"} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column flex-md-row">
          <p>
            Ventanilla Digital Jalisco ® 2021
          </p>
          <p
            onClick={() => privacyRequest(setPrivacyModalElements, setShowModal)}
            className="cursor-pointer ml-0 mb-0 mb-md-3 ml-md-4"
          >
            Aviso de privacidad
          </p>
        </div>
      </div>
      <Modal
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {privacyModalElements}
      </Modal>
    </div>
  );
}

export default Contact;
