import React from 'react'
import { useGlobalStateContext } from '../../../../context/globalState/GlobalStateContext'
import ModalComponent from '../../../helpers_components/ModalComponent';
import BodyModal from './components/bodyModal/BodyModal';
import FooterModal from './components/footerModal/FooterModal';

const SuccessfulPreregistrationModal = () => {

  const { successfulPreregistrationModal } = useGlobalStateContext();

  if (!successfulPreregistrationModal.isShowing) {
    return <></>
  }

  return (
    <ModalComponent
      show={successfulPreregistrationModal.isShowing}
      title={' Gracias por registrarte!'}
      body={<BodyModal />}
      footer={<FooterModal />}
      canClose={true}
      onHide={successfulPreregistrationModal.setIsShowing}
    />
  )
}

export default SuccessfulPreregistrationModal