import React from "react";
import { NewClassRequest } from "../classes/NewClassRequest";
import { NotificationManager } from "react-notifications";

export const initForm = {
  curp: "",
  paternal_surname: "",
  maternal_surname: "",
  names: "",
  rfc: "",
  email: "",
  phone: ""
};

/**********************************************************FORGOT PASSWORD************************************************************************/

export const finisRegistration = async (
  form,
  confirmation_token,
  props,
  subdomain,
  setErrors
) => {
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/auth/finish_registration`,
    "put",
    null,
    {
      confirmation_token: confirmation_token,
      curp: form.curp,
      names: form.names,
      paternal_surname: form.paternal_surname,
      maternal_surname: form.maternal_surname,
      rfc: form.rfc,
      phone: form.phone
    }
  );
  const validateConfirmRegistration = await request.executeRequest();

  if ( validateConfirmRegistration.code !== 200 ) {

    NotificationManager.error("Ha ocurrido un error vuelve a intentarlo");
  }
  else {

    if (
      validateConfirmRegistration.response.error && 
      typeof validateConfirmRegistration.response.message === "object"
    ) {

      setErrors( validateConfirmRegistration.response.message );
      NotificationManager.error( "Hay errores que impiden continuar, revisa los campos marcados en rojo" );
    } 
    else {

      setTimeout(() => {
        NotificationManager.success("¡Ahora ya puedes iniciar sesion!");
      }, 500);
      props.history.push(`/${subdomain}/`);
    }
  }
};

/***********************************************************************************************************************************************/

/**********************************************************FORGOT PASSWORD************************************************************************/

export const getUserConfirmData = async (tokenUrl, setUser) => {
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/auth/confirm`,
    "post",
    null,
    {
      confirmation_token: tokenUrl
    }
  );
  const getUserConfirmData = await request.executeRequest();

  if (getUserConfirmData.code === 200) {

    getUserConfirmData.response.user.user_type =
      getUserConfirmData.response.user.user_type === "manager"
        ? "Gestor"
        : "Interesado";
    setUser(getUserConfirmData.response.user);
  }
};

/***********************************************************************************************************************************************/
export const renderErrorsList = (errors, inputName) => {
  if ( errors && errors[inputName] ) {
    const errorsList = errors[inputName].map((error, index) => {
      return (
        <li key={index} style={{padding:"0px 25px 0px", color:"red", fontSize:".8rem"}}>
          <small>
            {error}
          </small>
        </li>
      );
    })
    return errorsList;
  }
};

export const renderClassesToErrors = (errors, inputName) => {
  if ( errors && errors[inputName] ) {
    return "text-danger is-invalid";
  }
};