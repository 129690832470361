import React, { useState, useEffect, useContext } from "react";
import { Col, Row } from "react-flexbox-grid";
import {
  DropdownButton,
  Dropdown,
  InputGroup,
  Button,
  FormControl,
  Modal
} from "react-bootstrap";
import { RemotePagination } from "../helpers_components/remote_pagination/RemotePagination";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import {
  getListProcessByStatus,
  columns,
  statusProcess,
  setFilterNameButton,
  columnsReview,
  columnsCanceled,
  columnsFinished,
  searchProcedure
} from "./process_list_logic";
import LoaderContext from "../layout/shared/loader_context";
import DomainContext from "../domains/domainPathContext";

const ProcessList = props => {
  const subdomain = useContext(DomainContext);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(5);
  const [totalProcedures, setTotalProcedures] = useState(5);
  const [procesStatus] = useState(statusProcess);
  const [currentStatusFilter, setCurrentStatusFilter] = useState(1);
  const [columnDefs, setColumnDefs] = useState(columns);
  const [searchValue, setSearchValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalElements, setModalElements] = useState({})

  //const [countTotalByFilter, setCountTotalByFilter] = useState({});

  const Loader = useContext(LoaderContext);

  useEffect(() => {
    (async function() {
      Loader.show(true);
      try {
        await getListProcessByStatus(
          props,
          setData,
          setTotalProcedures,
          sizePerPage,
          page,
          currentStatusFilter,
          subdomain,
          setShowModal,
          setModalElements
        );
        Loader.show(false);
      } catch (e) {
        console.log(e);
        Loader.show(false);
      }
    })();
  }, [currentStatusFilter]);

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setSizePerPage(sizePerPage);
    setPage(page);

    if (searchValue.length > 0 && /^$|.*\S+.*/.test(searchValue)) {
      await searchProcedure(
        searchValue,
        setData,
        setTotalProcedures,
        sizePerPage,
        page,
        currentStatusFilter,
        props,
        true,
        subdomain
        )
    }
    else {
      await getListProcessByStatus(
        props,
        setData,
        setTotalProcedures,
        sizePerPage,
        page,
        currentStatusFilter,
        subdomain,
        setShowModal,
        setModalElements
      );
    }
  };

  // Function to change status in table procedures
  const onChangeTableStatus = status => {
    // change columns in procedures with state is paused or review.
    if (status === 2 || status === 4) setColumnDefs(columnsReview);
    else if (status === 3) setColumnDefs(columnsCanceled);
    else if (status === 5) setColumnDefs(columnsFinished);
    else setColumnDefs(columns);

    setSearchValue('');
    setPage(1);
    setCurrentStatusFilter(status);
  };

  const processFilterStatus = procesStatus.map((el, index) => {
    return (
      <Dropdown.Item
        as="button"
        key={index}
        onClick={() => onChangeTableStatus(el.id)}
        className={index === 0 ? "d-n" : ""}
      >
        {el.name}
      </Dropdown.Item>
    );
  });

  const onEnterKeyTextBox = async (event) => {
    if ( event.key === "Enter" && searchValue.length >= 0 ) {
      await forceSearch();
    }
    else if( event.key === "Backspace" && searchValue.length === 1 ) {
      await clearSearch();
    }
  }

  const forceSearch = async () => {
    try {
      Loader.show(true);
      if (searchValue) await searchProcedure(
        searchValue,
        setData,
        setTotalProcedures,
        sizePerPage,
        page,
        currentStatusFilter,
        props,
        true,
        subdomain
        )
      else await getListProcessByStatus(
        props,
        setData,
        setTotalProcedures,
        sizePerPage,
        page,
        currentStatusFilter,
        setShowModal,
        setModalElements
      );
      Loader.show(false);
    } catch (e) {
      Loader.show(false);
    }
  };

  const clearSearch = async () => {
    setSearchValue("");
    let inputSearcher = document.getElementById("searcher");
    inputSearcher.value = "";

    try {
      await getListProcessByStatus(
        props,
        setData,
        setTotalProcedures,
        sizePerPage,
        page,
        currentStatusFilter,
        setShowModal,
        setModalElements
      );
      Loader.show(false);
    } catch (e) {

      Loader.show(false);
    }
  };

  return (
    <section className="process-list" style={{marginBottom:'40px'}}>
      <Row>
        <Col xs={12} sm={6}>
          <label className="d-block">
            Filtra tus trámites por estado aquí{" "}
          </label>
          <DropdownButton
            id="dropdown-item-button"
            title={setFilterNameButton(currentStatusFilter)}
            className="filter-btn-dropdown float-left"
          >
            {processFilterStatus}
          </DropdownButton>
        </Col>
        <Col xs={12} sm={6}>
          <InputGroup className="mT-30">
            <FormControl
              placeholder="Palabra o término a buscar "
              aria-label="Palabra o término a buscar "
              aria-describedby="basic-addon2"
              value={searchValue}
              onKeyDown={(e) => onEnterKeyTextBox(e)}
              onChange={(event) => setSearchValue(event.target.value)}
              id="searcher"
              style={{ fontSize: "1rem" }}
            />
            <InputGroup.Append>
              <Button
                onClick={forceSearch}
                disabled={Loader.status}
                className={"btn-search-pl"}
              >
                <i className="ti-search" style={{pointerEvents:"none"}} /> Buscar
              </Button>
            </InputGroup.Append>
          </InputGroup>
          <small>Escribe el término deseado y oprime el botón "Buscar"</small>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} className="procedures-table-container mT-20">
          {data.length === 0 && totalProcedures === 0 ?
            <div className='fl-r'>
              <strong>
                No se encontraron resultados por mostrar
              </strong>
            </div> : 
            <div className='fl-r'>
              <strong>
                Mostrando {
                (((sizePerPage * page) - sizePerPage) + 1) + ' - ' 
                + ((sizePerPage * page) > totalProcedures ? totalProcedures : 
                (sizePerPage * page))} de un total de {totalProcedures} resultados
              </strong>
            </div>}
          <div className="procedures-table-wrapper">
            <RemotePagination
              data={data}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalProcedures}
              onTableChange={handleTableChange}
              columns={columnDefs}
            />
          </div>
        </Col>
      </Row>
      <>
        <Modal
          size="lg"
          show={showModal}
          onHide={() => setShowModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          {modalElements}
        </Modal>
      </>
    </section>
  );
};
export default ProcessList;
