/* eslint-disable eqeqeq */
import axios from "axios";
import { NotificationManager } from "react-notifications";
import Cookies from "js-cookie";
import { destroyCookies } from "../login/login_logic";

export class NewClassRequest {
  constructor(url, method, headers, bodyRequest) {
    this.url = url;
    this.bodyRequest = bodyRequest;
    this.method = method;
    this.headers = headers;
  }

  async executeRequest() {
    let responseError = {
      code: 800,
      message:
        "Hubo un error al realizar la accion, intentelo mas tarde o contacte al administrador."
    };

    const authenticationToken = Cookies.get("authentication_token_02") ?
                                String(Cookies.get("authentication_token_02")) :
                                btoa(process.env.REACT_APP_TOKEN_WITHOUT_AUTHENTICATION);

    try {
      const response = await axios({
        method: this.method,
        url: this.url,
        data: this.bodyRequest,
        headers: {
          Authorization: authenticationToken
        }
      });

      if (response.data.code === 403 && response.data.data.clean) {
        destroyCookies();
        NotificationManager.error(
          '',
          'La sesión ha expirado:',
          4000
        );
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      }

      if ( response.status === 200 ) {

        return {
          code: response.data.code,
          response: response.data.data,
          responseComplete: response
        }
      }

    } catch (error) {

      responseError = {
        code: 500,
        message: "El servidor no responde"
      };

      NotificationManager.error(
        responseError.message,
        "Error " + responseError.code + ":"
      );
    }

    return responseError;
  }
}
