import React, { useState, useContext } from "react";
import { Col, Button } from "react-bootstrap";
import { requestFieldSubmit } from "../../dynamic_form/dynamic_form_logic";

const SubmitField = ({ el, onSaveHandler }) => {

  return (
    <>
      <Col
        className={ el.visibility["status"] ? "" : "d-none" }
        xs={12}
        md={el.width}
      >
        <label className="font-weight-bold">{el.label}</label>
        <div>
          <Button
            onClick={ async (e) => {
              await onSaveHandler(e);
              await requestFieldSubmit(
                el.extra_attributes.url,
                el.id
              );
            }}
          >         
            {el.extra_attributes.url_label}
          </Button>
        </div>
        <div className="w-100">
          <small id="redirectHelp" className="form-text text-muted">
            {el.contextual_help ? el.contextual_help : ""}
          </small>
        </div>
      </Col>
    </>
  );
};

export default SubmitField;