import React, { useState } from "react";
import ReactMapboxGl, { Layer, Feature, ZoomControl } from "react-mapbox-gl";
import _ from "lodash";
import Cookies from "js-cookie";
import Geocoder from "react-mapbox-gl-geocoder";
import "./map.css";
import { Col } from "react-flexbox-grid";

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAP_TOKEN
});

const queryParams = {
  country: "mx"
};

const DynamicMap = props => {
  const [viewport, setViewport] = useState({});
  const [mapLongitude, setMapLongitude] = useState(
    !_.isObject(props.el.value)
      ? props.el.extra_attributes.longitude
      : props.el.value.value.longitude
  );
  const [mapLatitude, setMapLatitude] = useState(
    !_.isObject(props.el.value)
      ? props.el.extra_attributes.latitude
      : props.el.value.value.latitude
  );

  const onMarkerDragEnd = async event => {
    await centerMap(event.lngLat.lat, event.lngLat.lng);
    props.onChangeHandler(
      { latitude: event.lngLat.lat, longitude: event.lngLat.lng },
      props.el.name,
      props.el.type
    );
  };

  const centerMap = async (lat, long) => {
    setMapLatitude(lat);
    setMapLongitude(long);
  };

  const disableFieldBasedMethod = el => {
    const { userId_02 } = Cookies.get();

    if (!el.readonly) {
      if (props.mode === 2) {
        return false;
      }
      if (props.mode === 1) {
        return true;
      } else {
        if (parseInt(userId_02) !== parseInt(props.applicant)) {
          return false;
        } else {
          if (el.observations) {
            return false;
          } else {
            return true;
          }
        }
      }
    }
    if (el.readonly === 1) {
      if (props.mode === 2) {
        return false;
      }
      if (props.mode === 1) {
        return false;
      } else {
        if (parseInt(userId_02) !== parseInt(props.applicant)) {
          return false;
        } else {
          if (el.observations) {
            return false;
          } else {
            return true;
          }
        }
      }
    }
  };

  const validateCloudVisibility = el => {
    if (el.is_validable && props.mode === 3) {
      if (el.observations) {
        return { display: "inline-block" };
      } else {
        return { display: "none" };
      }
    } else {
      return { display: "none" };
    }
  };

  const onSelectedGoecoder = async (viewport, item) => {
    setViewport({ viewport });
    await centerMap(viewport.latitude, viewport.longitude);
    props.onChangeHandler(
      { latitude: viewport.latitude, longitude: viewport.longitude },
      props.el.name,
      props.el.type
    );
  };

  const settingDefaultValues = (e) =>{
    if(e.target.value === "Buscar..." ) return e.target.value = "";
  };

  return (
    <Col
      className="p-2 pt-3 h-100"
      xs={12}
    >
      <b>
        <label
          htmlFor={`FormControlFile${props.el.name}`}
          className={
            "font-weight-bold " + (props.el.error ? "text-danger" : "")
          }
        >
          <span
            style={
              props.el.requiredSignal
                ? { display: "inline" }
                : { display: "none" }
            }
          >
            <b>* </b>
          </span>
          {props.el.label}
          <span
            className="observation-input-icon"
            style={validateCloudVisibility(props.el)}
            onClick={() => props.setShow(props.el)}
          >
            <i
              className={
                "far fa-comment-dots" +
                (props.el.observations ? " text-danger" : "")
              }
            />
          </span>
        </label>
      </b>
      <div onClick={(e)=>settingDefaultValues(e)}>
        <Geocoder
          initialInputValue="Buscar..."
          mapboxApiAccessToken={process.env.REACT_APP_MAP_TOKEN}
          onSelected={onSelectedGoecoder}
          viewport={viewport}
          hideOnSelect={true}
          queryParams={queryParams}
        />
      </div>

      <Map
        // eslint-disable-next-line react/style-prop-object
        style={process.env.REACT_APP_MAP_STYLES}
        containerStyle={{
          width: "100%",
          height: "35rem",
          minHeight: "35rem",
          zIndex: 1
        }}
        zoom={[props.el.extra_attributes.zoom]}
        center={[mapLongitude, mapLatitude]}
      >

        <Layer type="circle" id="marker" paint={{
          'circle-color': "#fff",
          'circle-stroke-width': 10,
          'circle-stroke-color': '#ff5200',
          'circle-stroke-opacity': 1
        }}>
          <Feature
            coordinates={[mapLongitude, mapLatitude]}
            draggable={disableFieldBasedMethod(props.el)}
            onDragEnd={onMarkerDragEnd}
          />
        </Layer>
        <ZoomControl
          onControlClick={(map, zoomDiff) => {
            map.setZoom(map.getZoom() + zoomDiff);
          }}
        />
      </Map>
      <small id={`small${props.el.name}`} className="form-text text-muted">
        {props.el.contextual_help ? props.el.contextual_help : ""}
      </small>
      {props.errorsMap}
    </Col>
  );
};

export default DynamicMap;
