import React, { useContext, useState } from "react";
import { Card, Col, Container, Form, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import GroupButtonsForm from "../helpers_components/GroupSubmitFormButtons";
import { forgotPassword, initForm } from "./forgot_password_logic";
import Plain from "../layout/containers/Plain";
import LoaderContext from "../layout/shared/loader_context";
import DomainContext from "../domains/domainPathContext";
import Cookies from "js-cookie";

const ForgotPassword = props => {
  const subdomain = useContext(DomainContext);
  const layout = JSON.parse(Cookies.get("layout_02"));

  const [form, setForm] = useState(initForm);
  const Loader = useContext(LoaderContext);

  const onChangeEventHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onSubmitHandler = async e => {
    e.preventDefault();
    Loader.show(true);
    await forgotPassword(form, props, subdomain);
    Loader.show(false);
  };

  return (
    <Plain {...props} noHeader={true} noFooter={true}>
      <Container
        fluid={true}
        className="min-height-100vh bg-size-cover"
        style={{
          backgroundImage:
            layout && layout['app_background_undefined_session'] ? 
            `url(${layout['app_background_undefined_session']})` :
            "url(" + process.env.PUBLIC_URL + "/img/login_wallpaper.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"
            
        }}
      >
        <Container>
          <Row style={{ minHeight: "100vh" }}>
            <Col
              sm={12}
              md={{ span: 10, offset: 1 }}
              lg={{ span: 8, offset: 2 }}
              xl={{ span: 6, offset: 3 }}
              className="mY-30"
            >
              <Card className="mY-40@sm+">
                <Card.Header>
                  <Container>
                    <Row>
                      <Col
                        xs={12}
                        md={{ span: 10, offset: 1 }}
                        className="pT-20 pB-10 pT-30@sm+ pB-20@sm+"
                      >
                        <h3 className="mb-0 text-center">Recuperar Cuenta</h3>
                      </Col>
                    </Row>
                  </Container>
                </Card.Header>
                <Card.Body>
                  <Container>
                    <Row>
                      <Col
                        xs={12}
                        md={{ span: 10, offset: 1 }}
                        className="pT-30"
                      >
                        <p className="mB-30 text-justify">
                          Para recuperar la contraseña por favor llena el
                          siguiente formulario
                        </p>

                        <Form onSubmit={e => onSubmitHandler(e)}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label className="font-weight-bold">
                              * Email
                            </Form.Label>
                            <OverlayTrigger
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                Ingresa el correo electrónico con le cual registraste tu cuenta.
                              </Tooltip>
                            }
                          >
                            <Form.Control
                              type="email"
                              placeholder="Correo Electronico"
                              autoFocus
                              onChange={onChangeEventHandler}
                              name="email"
                            />
                          </OverlayTrigger>
                          </Form.Group>

                          <div className="pY-20">
                            <GroupButtonsForm
                              primaryText="Continuar"
                              secondaryText="Cancelar"
                              urlToCancel={`/${subdomain}`}
                            />
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </Plain>
  );
};

export default ForgotPassword;
