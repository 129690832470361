import useSuccessfulPreregistrationModal from './useSuccessfulPreregistrationModal';

const useGlobalState = () => {

  const successfulPreregistrationModal = useSuccessfulPreregistrationModal();

  return {
    successfulPreregistrationModal,
  }
}

export default useGlobalState;