import Cookies from "js-cookie";
import { Request } from "../classes/Request";

/**********************************************************FORGOT PASSWORD************************************************************************/

export const getSectionsByProcess = async id => {
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/sections/${id}?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const getSectionProcess = await request.executeRequest();

  return {
    code: getSectionProcess.code,
    data: getSectionProcess.data
  };
};

/***********************************************************************************************************************************************/

export const getSectionContent = async (process_id, section_id) => {
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/section/${section_id}/${process_id}?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const getSectionContentData = await request.executeRequest();

  return {
    code: getSectionContentData.code,
    data: getSectionContentData.data
  };
};

/***********************************************************************************************************************************************/

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////FUNCION QUE EJECUTA EL FORMULARIO /////////////////////////////////////////////////////////////////////

export const startProcess = async (processId, props, subdomain) => {

  const { userId_02 } = Cookies.get();
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/start_procedure`,
    "post",
    null,
    {
      user_id: userId_02,
      token: process.env.REACT_APP_TOKEN,
      process_id: processId
    }
  );
  const startProcessData = await request.executeRequest();
  if (startProcessData.code === 200) {
    const actionTo = {
      action: "start",
      processId: processId,
      procedure_id: startProcessData.data.data.data.meta.procedure_id
    };
    localStorage.setItem("actionTo",JSON.stringify(actionTo));

    let meta = startProcessData.data.data.data.meta;
    let form = startProcessData.data.data.data.form;

    if (props)
      props.history.push( {
        pathname: `/${subdomain}/render_form`,
        state: {
          params: {
            stage: meta.stage_id,
            sequence: meta.current_step,
            form: form,
            formTitle: meta.form_name,
            mode: meta.mode,
            applicant: meta.applicant_id
          }
        }
      })
    else return {
      meta:meta,
      form:form
    };
  }
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
