import React from 'react'
import { useGlobalStateContext } from '../../../../../../context/globalState/GlobalStateContext';
import { Button } from 'react-bootstrap';

const FooterModal = () => {

  const { successfulPreregistrationModal } = useGlobalStateContext();

  const handleCloseButtonClick = () => {
    successfulPreregistrationModal.setIsShowing(false);
  }

  return (
    <Button variant='secondary' onClick={handleCloseButtonClick}>
      Cerrar
    </Button>
  )
}

export default FooterModal