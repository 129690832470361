import { NewClassRequest } from "../classes/NewClassRequest";
import { NotificationManager } from "react-notifications";
import Cookies from 'js-cookie';

export const initForm = {
  email: ""
};
export const initFormRecovery = {
  password: "",
  password_confirmation: ""
};

/**********************************************************FORGOT PASSWORD************************************************************************/

export const forgotPassword = async (form, props, subdomain) => {
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/auth/password`,
    "post",
    null,
    {
      email: form.email,
      app_id: 2,
      domain_name: Cookies.get('domain_name_02')
    }
  );

  const validateEmail = await request.executeRequest();

  if ( validateEmail.code !== 200 ) {

    NotificationManager.success("No fue posible completar la solicitud, inténtalo de nuevo");
  }
  else {

    if (
      validateEmail.response.error && 
      validateEmail.response.message !== "" &&
      typeof validateEmail.response.message === "string"
    ) {

      NotificationManager.error( validateEmail.response.message );
    }
    else if ( 
      !validateEmail.response.error && 
      validateEmail.response.message !== "" &&
      typeof validateEmail.response.message === "string"
    ) {

      props.history.push(`/${subdomain}`);
      NotificationManager.success( validateEmail.response.message );
    }
  }
};

/***********************************************************************************************************************************************/

export const recoveryPassword = async (
  form,
  reset_password_token,
  props,
  subdomain
) => {
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/auth/password`,
    "put",
    null,
    {
      password: form.password,
      password_confirmation: form.password_confirmation,
      reset_password_token: reset_password_token
    }
  );
  const validateRecoveryPassowrd = await request.executeRequest();

  if (validateRecoveryPassowrd.code !== 200) {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    NotificationManager.error( "Ocurrió un error y la contraseña no fue actualizada, inténtalo nuevamente" );
    return;
  }
  else {

    if (
      !validateRecoveryPassowrd.response.error &&
      typeof validateRecoveryPassowrd.response.message === "string" &&
      validateRecoveryPassowrd.response.message !== ""
    ) {


      setTimeout(() => {
        NotificationManager.success(
          validateRecoveryPassowrd.response.message
        );
      }, 500);

      props.history.push(`/${subdomain}/`);
    }
    else if (
      validateRecoveryPassowrd.response.error && 
      validateRecoveryPassowrd.response.message !== "" &&
      typeof validateRecoveryPassowrd.response.message === "string"
    ) {

      NotificationManager.error( validateRecoveryPassowrd.response.message );
    }
  }
};

/*************************************************************************************************************************************************/
