import React, { useState, useContext } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Form, Button, Card } from "react-bootstrap";
import AlertFormValidation from "../helpers_components/alertValidation";
import { initForm, validateStringConfirm } from "./request_offline_logic";
import Plain from "../layout/containers/Plain";
import DomainContext from "../domains/domainPathContext";

const RequestOfffline = ({ history }) => {
  const subdomain = useContext(DomainContext);
  const [form, setForm] = useState(initForm);

  const onChangeEventHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onSubmitHandler = async e => {
    e.preventDefault();
    const validations = await validateStringConfirm(form.customKey);
    if (validations.code === 200) {
      history.push({
        pathname: `/${subdomain}/notification_offline`,
        state: { detail: validations.data.data.procedure }
      });
    } else {
      setForm(validations);
    }
  };

  return (
    <Plain noHeader={true} noFooter={true}>
      <section className="request_offline">
        <Row>
          <Col xs={12} className="col-sm-4 col-sm-offset-4 mY-40">
            <Card className="w-100">
              <Card.Body>
                <Form onSubmit={onSubmitHandler}>
                  <Form.Group controlId="formBasicStringConfirmation">
                    <Form.Label>
                      <b>* Codigo de tramite</b>
                    </Form.Label>

                    <Form.Control
                      type="text"
                      placeholder="Codigo de tramite"
                      autoFocus
                      name="customKey"
                      onChange={onChangeEventHandler}
                      value={form.customKey}
                    />
                    <Form.Text
                      className="text-muted"
                      style={{ textAlign: "start" }}
                    >
                      Deberas ingresar en este campo el codigo del tramite que
                      fue enviado a tu correo electronico.
                    </Form.Text>
                  </Form.Group>

                  <AlertFormValidation
                    errors={form.errors}
                    styleClassAlert={
                      form.styleClassAlert ? "success" : "danger"
                    }
                    title={form.title}
                    message=""
                    visible={form.visible}
                  />
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    style={{ minWidth: "150px" }}
                  >
                    Consultar
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </Plain>
  );
};

export default RequestOfffline;
