import Cookies from "js-cookie";

export const xmlHttpRequestManualDownload = (url, success) => {
  const { authentication_token_02 } = Cookies.get();
  let xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.setRequestHeader('Authorization', authentication_token_02);
  xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  xhr.responseType = 'arraybuffer';
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      if (success) {
        success(xhr.response);
      }
    }
  };
  xhr.send();
};