import { Request } from "./components/classes/Request";
import Cookies from "js-cookie";
import {destroyCookies} from "./components/login/login_logic";

/**********************************************************GET DOMAIN NAME************************************************************************/
export const domainRequest = async ( domainName, setDomainPath ) => {
  const isAuth = Cookies.get("isAuth_02") ? Cookies.get("isAuth_02") : null;
  const domainNameCookie = Cookies.get("domain_name_02") ? Cookies.get("domain_name_02") : null;

  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/domains/find_by_name?token=${process.env.REACT_APP_TOKEN}&domainName=${domainName}`,
    "get",
    null,
    {}
  );
  const domainRequestData = await request.executeRequest();
  if (domainRequestData.code === 200) {
    if ( isAuth && (domainNameCookie !== domainName) ) {

      await destroyCookies();
      Cookies.set( "domain_id_02", domainRequestData.data.data.data.domain_id );
      Cookies.set( "domain_name_02", domainRequestData.data.data.data.domain_name );
      setDomainPath( domainRequestData.data.data.data.domain_name );
      return "Login";
    }
    else {
      Cookies.set("domain_id_02", domainRequestData.data.data.data.domain_id );
      Cookies.set("domain_name_02", domainRequestData.data.data.data.domain_name );
      setDomainPath( domainRequestData.data.data.data.domain_name );
    }
    return "Normal";
  } else {
    return "NotFound";
  }
};
/***********************************************************************************************************************************************/
