import React from "react";
import queryString from "query-string";
import MoralPerson from "./MoralPerson";
import PhysicalPerson from "./PhysicalPerson";
import NotFound from "../error_page/404";
import Plain from "../layout/containers/Plain";
import {Col, Container, Row} from "react-bootstrap";
import Cookies from "js-cookie";

const FinishRegistration = props => {
    const layout = JSON.parse(Cookies.get("layout_02"));
    const getParams = queryString.parse(props.location.search);
    if (
        typeof getParams.reg !== "undefined" &&
        typeof getParams.token !== "undefined"
    ) {
        if (getParams.reg === "1") {
            return (
                <Plain {...props} noHeader={true} noFooter={true}>
                    <Container 
                        fluid={true} 
                        className="min-height-100vh bg-size-cover" 
                        style={{
                            backgroundImage: layout && layout['app_background_undefined_session'] ? 
                                `url(${layout['app_background_undefined_session']})` :
                                "url(" + process.env.PUBLIC_URL + "/img/login_wallpaper.png)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center"
                        }}
                    >
                        <Container>
                            <Row style={{minHeight: "100vh"}}>
                                <Col sm={12} md={{span: 10, offset: 1}} lg={{span: 6, offset: 3}} className="pY-40@sm+">
                                    <PhysicalPerson token={getParams.token} history={props.history}/>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </Plain>
            );
        } else if (getParams.reg === "2") {
            return (
                <Plain {...props} noHeader={true} noFooter={true}>
                    <Container 
                        fluid={true} 
                        className="min-height-100vh bg-size-cover" 
                        style={{
                            backgroundImage: layout && layout['app_background_undefined_session'] ? 
                                `url(${layout['app_background_undefined_session']})` :
                                "url(" + process.env.PUBLIC_URL + "/img/login_wallpaper.png)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center"
                        }}
                    >
                        <Container>
                            <Row style={{minHeight: "100vh"}}>
                                <Col sm={12} md={{span: 10, offset: 1}} lg={{span: 6, offset: 3}} className="pY-40@sm+">
                                    <MoralPerson token={getParams.token} history={props.history}/>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </Plain>
            );
        } else {
            return <NotFound/>;
        }
    } else {
        return <NotFound/>;
    }
};

export default FinishRegistration;
