import React from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Row, Col } from "react-flexbox-grid";

const GroupButtonsForm = props => {
  const history = useHistory();

  const { primaryButtonProps = {} } = props;

  return (
    <Row>
      <Col xs={12} sm={12} className="mT-20">
        {props.login ? (
          <Button
            style={{ width: "45%" }}
            variant="outline-light"
            type="button"
            className="p-5 group-button-submit btn-responsive-cancel mB-10@sm-"
            onClick={(e) => { history.goBack() }}
          >
            {props.secondaryText}
          </Button>
        ) : (
          <Link to={props.urlToCancel ? props.urlToCancel : "/"}>
            <Button
              style={{ width: "45%" }}
              variant="outline-light"
              type="button"
              className="p-5 group-button-submit btn-responsive-cancel mB-10@sm-"
            >
              {props.secondaryText}
            </Button>
          </Link>
        )}
        <Button
          style={{ width: "45%" }}
          variant="outline-light"
          type="submit"
          className="p-5 group-button-submit float-right btn-responsive"
          {...primaryButtonProps}
        >
          {props.primaryText}
        </Button>
      </Col>
    </Row>
  );
};

export default GroupButtonsForm;
