import React, { useState, useContext, useEffect } from "react";
import { Card, Form, Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import GroupButtonsForm from "../helpers_components/GroupSubmitFormButtons";
import {
  register,
  initForm,
  termsRequest,
  ModalTermsAndConditions
} from "./register_logic";
import GoogleRecaptcha from "../helpers_components/GoogleRecaptcha";
import Plain from "../layout/containers/Plain";
import LoaderContext from "../layout/shared/loader_context";
import DomainContext from "../domains/domainPathContext";
import { renderErrorsList, renderClassesToErrors } from "../confirm_registration/confirm_registration_logic";
import Cookies from "js-cookie";
import { useGlobalStateContext } from "../../context/globalState/GlobalStateContext";


const Register = props => {
  const subdomain = useContext(DomainContext);
  const [form, setForm] = useState(initForm);
  const Loader = useContext(LoaderContext);
  const [recaptcha, setRecaptcha] = useState("");
  const [handleTermsModal, setHandleTermsModal] = useState(false);
  const [termsText, setTermsText] = useState(false);
  const [termsTitle, setTermsTitle] = useState("");
  const [isPrivacyNoticeAccepted, setIsPrivacyNoticeAccepted] = useState(false);
  const [canShowModal, setCanShowModal] = useState(true);
  const [errors, setErrors] = useState({});
  const layout = JSON.parse(Cookies.get("layout_02"));

  const { successfulPreregistrationModal } = useGlobalStateContext();

  useEffect(() => {
    (async function () {
      await termsRequest(setTermsTitle, setTermsText, setCanShowModal);
    })();
  }, []);

  const handleChangeForm = event => {
    if (event.target.name === 'acceptPrivacyNoticeCheckbox') {
      setIsPrivacyNoticeAccepted(event.target.checked)
      return
    }
    setForm({
      ...form,
      [event.target.name]: event.target.value
    });
    Object.keys(errors).forEach((error) => {
      if (error === event.target.name) {
        delete errors[event.target.name];
      }
    });
  };

  const onSubmitFormHandler = async event => {
    event.preventDefault();
    Loader.show(true);
    await register(form, recaptcha, props, subdomain, setErrors, successfulPreregistrationModal);
    Loader.show(false);
  };

  const ModalToTermsAndConditions = ModalTermsAndConditions(
    handleTermsModal,
    setHandleTermsModal,
    termsText,
    termsTitle
  );

  return (
    <Plain {...props} noHeader={true} noFooter={true}>
      <Container
        fluid={true}
        className="min-height-100vh bg-size-cover"
        style={{
          backgroundImage: layout && layout['app_background_undefined_session'] ?
            `url(${layout['app_background_undefined_session']})` :
            "url(" + process.env.PUBLIC_URL + "/img/login_wallpaper.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"
        }}
      >
        <Container>
          <Row style={{ minHeight: "100vh" }}>
            <Col
              sm={12}
              md={{ span: 10, offset: 1 }}
              lg={{ span: 8, offset: 2 }}
              xl={{ span: 6, offset: 3 }}
              className="mY-30"
            >
              <Card className="mY-40@sm+">
                <Card.Header>
                  <Container>
                    <Row>
                      <Col
                        xs={12}
                        md={{ span: 10, offset: 1 }}
                        className="pT-20 pB-10 pT-30@sm+ pB-20@sm+"
                      >
                        <h3 className="mb-0">Crear Cuenta</h3>
                      </Col>
                    </Row>
                  </Container>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs={12} md={{ span: 10, offset: 1 }}>
                      <p>
                        Para registrarte, por favor completa el siguiente
                        formulario.
                      </p>
                      <hr />
                      <Form onSubmit={onSubmitFormHandler} className="mB-20">
                        <Form.Group>
                          <Form.Label className="font-weight-bold">
                            * Regimen Fiscal
                          </Form.Label>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                <strong>Persona Física:</strong> Selecciona esta opción si tu actividad económica te permite identificarte por tu nombre. <br />
                                <strong>Persona moral:</strong> Selecciona esta opción si tu actividad económica tiene su propia razón social o denominación independientemente del nombre de quienes la conforman.
                              </Tooltip>
                            }
                          >
                            <Form.Control
                              name="regime"
                              onChange={handleChangeForm}
                              as="select"
                              required
                            >
                              <option value="">Regimen Fiscal...</option>
                              <option value="1">Persona Fisica</option>
                              <option value="2">Persona Moral</option>
                            </Form.Control>
                          </OverlayTrigger>
                        </Form.Group>

                        <Form.Group controlId="formBasicNombre">
                          <Form.Label className="font-weight-bold">
                            * Nombre de Usuario
                          </Form.Label>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                Crea un usuario que contenga mínimo 6 caracteres, sin espacios. <br />
                                Puede contener letras en minúscula, números, guion bajo y punto. <br />
                                No caracteres especiales.
                              </Tooltip>
                            }
                          >
                            <Form.Control
                              type="text"
                              placeholder="Nombre de Usuario"
                              required
                              name="username"
                              onChange={handleChangeForm}
                              autoComplete="off"
                              className={renderClassesToErrors(errors, "username")}
                            />
                          </OverlayTrigger>
                          {renderErrorsList(errors, "username")}
                        </Form.Group>

                        <Form.Group controlId="formBasicCorreoElectronico">
                          <Form.Label className="font-weight-bold">
                            * Correo
                          </Form.Label>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                Ingresa el email que será utilizado para la confirmación de tu cuenta.
                              </Tooltip>
                            }
                          >
                            <Form.Control
                              type="email"
                              placeholder="Correo Electronico"
                              required
                              name="email"
                              onChange={handleChangeForm}
                              autoComplete="off"
                              className={renderClassesToErrors(errors, "email")}
                            />
                          </OverlayTrigger>
                          {renderErrorsList(errors, "email")}
                        </Form.Group>

                        <Form.Group controlId="formSelectAplicante">
                          <Form.Label className="font-weight-bold">
                            * Tipo de Usuario
                          </Form.Label>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                <strong>Interesado:</strong> Selecciona esta opción si tu cuenta es para uso personal. <br />
                                <strong>Gestor:</strong> Selecciona esta opción si tu cuenta es para la gestión de trámites de diferentes tipos de personas.
                              </Tooltip>
                            }
                          >
                            <Form.Control
                              onChange={handleChangeForm}
                              as="select"
                              name="user_type"
                              required
                            >
                              <option value="">Tipo de Aplicante...</option>
                              <option value="owner">Interesado</option>
                              <option value="manager">Gestor</option>
                            </Form.Control>
                          </OverlayTrigger>
                        </Form.Group>

                        <Form.Group controlId="formBasicCorreoContraseña">
                          <Form.Label className="font-weight-bold">
                            * Contraseña
                          </Form.Label>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                Crea una contraseña de mínimo 8 caracteres.
                              </Tooltip>
                            }
                          >
                            <Form.Control
                              type="password"
                              placeholder="Contraseña"
                              required
                              name="password"
                              onChange={handleChangeForm}
                              className={renderClassesToErrors(errors, "password")}
                            />
                          </OverlayTrigger>
                          {renderErrorsList(errors, "password")}
                        </Form.Group>

                        <Form.Group controlId="formBasicCorreoConfirmarContraseña">
                          <Form.Label className="font-weight-bold">
                            * Confirmar contraseña
                          </Form.Label>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                Ingresa nuevamente la contraseña que creaste en el campo anterior.
                              </Tooltip>
                            }
                          >
                            <Form.Control
                              type="password"
                              placeholder="Confirmar Contraseña"
                              required
                              name="password_confirmation"
                              onChange={handleChangeForm}
                              className={renderClassesToErrors(errors, "password_confirmation")}
                            />
                          </OverlayTrigger>
                          {renderErrorsList(errors, "password_confirmation")}
                        </Form.Group>

                        <div className="mb-3">
                          <Link to={`/${subdomain}/`}>Ya tengo cuenta</Link>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                          <Form.Group className="m-0 pr-1" controlId="formBasicCheckbox">
                            <Form.Check
                              name={'acceptPrivacyNoticeCheckbox'}
                              type={'checkbox'}
                              onChange={handleChangeForm}
                              checked={isPrivacyNoticeAccepted}
                            />
                          </Form.Group>
                          <p className="m-0" style={{ lineHeight: '1.2rem' }}>
                            * He leído y acepto los
                            <span
                              onClick={
                                canShowModal
                                  ? () => {
                                    setHandleTermsModal(true);
                                  }
                                  : null
                              }
                              className="cursor-pointer px-1"
                              style={{ color: '#00a7e1' }}
                            >
                              términos y condiciones
                            </span>
                            del presente aviso de privacidad.
                          </p>
                        </div>

                        <div className="overflow-auto w-100">
                          <GoogleRecaptcha
                            validate={value => setRecaptcha(value)}
                          />
                        </div>
                        {!recaptcha ? renderErrorsList(errors, "recaptcha") : null}
                        <GroupButtonsForm
                          login={true}
                          primaryText="Registrarme"
                          secondaryText="Cancelar"
                          urlToCancel={`/${subdomain}`}
                          primaryButtonProps={{ disabled: !isPrivacyNoticeAccepted }}
                        />
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
      <>
        {ModalToTermsAndConditions}
      </>
    </Plain>
  );
};

export default Register;
