import React, { useContext, useState } from "react";
import { Card, Container, Form, Col, Row } from "react-bootstrap";
import { changePassword, initForm } from "./change_password_logic";
import GroupButtonsForm from "../helpers_components/GroupSubmitFormButtons";
import LoaderContext from "../layout/shared/loader_context";
import OneColumn from "../layout/containers/OneColumn";
import DomainContext from "../domains/domainPathContext";
import Cookies from "js-cookie";

const ChangePassword = props => {
  const subdomain = useContext(DomainContext);
  const layout = JSON.parse(Cookies.get("layout_02"));
  const [form, setForm] = useState(initForm);
  const Loader = useContext(LoaderContext);

  let pageConfig = {
    active: "",
    title: ""
  };

  const onChangeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };
  const onSubmitHandler = async e => {
    e.preventDefault();
    Loader.show(true);
    await changePassword(form);
    Loader.show(false);
  };

  return (
    <OneColumn
      {...props}
      pageConfig={pageConfig}
      hideTopMenu={true}
      fluid={true}
      noTitle={true}
    >
      <Container
        fluid={true}
        className="bg-size-cover"
        style={{
          minHeight: "76vh",
          backgroundImage: layout && layout['app_background_session'] ? 
              `url(${layout['app_background_session']})` :
              "url(" + process.env.PUBLIC_URL + "/img/login_wallpaper.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"
        }}
      >
        <Container>
          <Row>
            <Col
              sm={12}
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
              className="mY-30"
            >
              <Card className="mY-40@sm+">
                <Card.Header>
                  <Container>
                    <Row>
                      <Col
                        xs={12}
                        md={{ span: 10, offset: 1 }}
                        className="pT-20 pB-10 pT-30@sm+ pB-20@sm+"
                      >
                        <h3 className="mB-20 text-center">
                          Actualizar contrase&ntilde;a
                        </h3>
                      </Col>
                    </Row>
                  </Container>
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={e => onSubmitHandler(e)}>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>
                        <b>* Nueva contraseña</b>
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Nueva Contraseña"
                        onChange={onChangeHandler}
                        name="newPassword"
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicNewPassword">
                      <Form.Label>
                        <b>* Confirmar nueva contraseña</b>
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Confirmar Nueva Contraseña"
                        onChange={onChangeHandler}
                        name="passwordConfirm"
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicOldPassword">
                      <Form.Label>
                        <b>* Contraseña actual</b>
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Contraseña anterior"
                        onChange={onChangeHandler}
                        name="oldPassword"
                      />
                    </Form.Group>

                    <div className="pY-20">
                      <GroupButtonsForm
                        primaryText="Cambiar"
                        secondaryText="Cancelar"
                        urlToCancel={`/${subdomain}/home`}
                      />
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </OneColumn>
  );
};

export default ChangePassword;
