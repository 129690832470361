import React from "react";
import { Modal } from "react-bootstrap";

const ModalComponent = ({
  show, modalClass, headerClass, 
  bodyClass, title, body, footer, 
  canClose, onHide, size
}) => {

  return (
    <>
      <Modal 
        show={show} 
        size={size ? size : ''}
        className={
          modalClass ? 
          modalClass : ""
        } 
        onHide={ canClose ? () => onHide(false) : () => onHide(true)}
      >
        <Modal.Header className={headerClass ? headerClass : ""}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={bodyClass ? bodyClass : ""}
        >
          {body}
        </Modal.Body>
        <Modal.Footer>
          {footer}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalComponent;